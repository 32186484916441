import moment from "moment";

export default class Rules {
  static required(v): boolean {
    return v != undefined && v != "";
  }

  static date(v): boolean {
    if (v) {
      const date = moment(v, "DD.MM.YYYY", true);
      return date.isValid();
    }

    return true;
  }

  static birthdate(v): boolean {
    if (v) {
      const date = moment(v, "DD.MM.YYYY", true);
      return date.isValid() && date.isBefore(moment());
    }

    return true;
  }
}


import { Component, Emit, Model, Vue, Watch } from "vue-property-decorator";
import Rules from "@/app/modules/_global/classes/Rules";
import { mask } from "vue-the-mask";

@Component({
  directives: {
    mask
  }
})
export default class RegisterForm extends Vue {
  @Model("input", { type: Object }) readonly userData!: object;

  @Emit()
  input() {
    return this.localUserData;
  }

  localUserData!: object;

  valid: boolean = true as boolean;
  errors: object = {} as object;

  rules = {
    required: Rules.required,
    birthdate: Rules.birthdate
  };

  constructor() {
    super();

    this.localUserData = this.userData;
  }

  get salutations() {
    return [
      {
        text: this.$t("PROFILE.SALUTATIONS.NULL"),
        value: null
      },
      {
        text: this.$t("PROFILE.SALUTATIONS.MISTER[1]"),
        value: "Mr."
      },
      {
        text: this.$t("PROFILE.SALUTATIONS.MISSES[1]"),
        value: "Mrs."
      },
      {
        text: this.$t("PROFILE.SALUTATIONS.MISS[1]"),
        value: "Ms."
      }
    ];
  }

  @Watch("localUserData", { deep: true })
  onLocalUserDataChanged() {
    this.input();
  }
}
